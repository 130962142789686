import { useEffect, useState } from "react";
import { Web3ReactProvider, useWeb3React } from "@web3-react/core";

import { InjectedConnector } from "@web3-react/injected-connector";
import { NetworkConnector } from "@web3-react/network-connector";
import classNames from "classnames";

import { ethers, utils } from "ethers";

const injected = new InjectedConnector({ supportedChainIds: [1] });
const network = new NetworkConnector({
  urls: { 1: "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161" },
});

function getLibrary(provider) {
  return new ethers.providers.Web3Provider(provider);
}

const abi = [
  {
    constant: true,
    inputs: [{ name: "_handle", type: "bytes32" }],
    name: "isHandleValid",
    outputs: [{ name: "", type: "bool" }],
    payable: false,
    stateMutability: "pure",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "_to", type: "address" },
      { name: "_tokenId", type: "uint256" },
    ],
    name: "approve",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "totalSupply",
    outputs: [{ name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ name: "_tokenId", type: "uint256" }],
    name: "approvedFor",
    outputs: [{ name: "", type: "address" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [{ name: "price", type: "uint256" }],
    name: "setAuctionPrice",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [],
    name: "withdraw",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ name: "_handle", type: "bytes32" }],
    name: "getHandleOwner",
    outputs: [{ name: "", type: "address" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "defaultAuctionDuration",
    outputs: [{ name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ name: "_owner", type: "address" }],
    name: "tokensOf",
    outputs: [{ name: "", type: "uint256[]" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ name: "_tokenId", type: "uint256" }],
    name: "ownerOf",
    outputs: [{ name: "", type: "address" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ name: "_owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      { name: "_handle", type: "bytes32" },
      { name: "_key", type: "string" },
    ],
    name: "getRecord",
    outputs: [{ name: "", type: "string" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [{ name: "_handle", type: "bytes32" }],
    name: "create",
    outputs: [],
    payable: true,
    stateMutability: "payable",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ name: "_tokenId", type: "uint256" }],
    name: "handleForTokenId",
    outputs: [{ name: "", type: "bytes32" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "_tokenId", type: "uint256" },
      { name: "_startingPrice", type: "uint256" },
      { name: "_endingPrice", type: "uint256" },
      { name: "_duration", type: "uint256" },
    ],
    name: "approveAndAuction",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "auctions",
    outputs: [{ name: "", type: "address" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "owner",
    outputs: [{ name: "", type: "address" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ name: "_handle", type: "bytes32" }],
    name: "isHandleAvailable",
    outputs: [{ name: "", type: "bool" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [{ name: "duration", type: "uint256" }],
    name: "setAuctionDuration",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "_handle", type: "bytes32" },
      { name: "_key", type: "string" },
      { name: "_value", type: "string" },
    ],
    name: "setRecord",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [{ name: "newOwner", type: "address" }],
    name: "changeOwner",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "_to", type: "address" },
      { name: "_tokenId", type: "uint256" },
    ],
    name: "transfer",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ name: "_handle", type: "bytes32" }],
    name: "tokenIdForHandle",
    outputs: [{ name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [{ name: "_tokenId", type: "uint256" }],
    name: "takeOwnership",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "defaultBuyNowPrice",
    outputs: [{ name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [{ name: "price", type: "uint256" }],
    name: "setBuyNowPrice",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "defaultAuctionPrice",
    outputs: [{ name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ name: "auctionAddress", type: "address" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "constructor",
  },
  { payable: true, stateMutability: "payable", type: "fallback" },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: "handle", type: "bytes32" },
      { indexed: true, name: "key", type: "string" },
      { indexed: false, name: "value", type: "string" },
    ],
    name: "SetRecord",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: "_from", type: "address" },
      { indexed: true, name: "_to", type: "address" },
      { indexed: false, name: "_tokenId", type: "uint256" },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: "_owner", type: "address" },
      { indexed: true, name: "_approved", type: "address" },
      { indexed: false, name: "_tokenId", type: "uint256" },
    ],
    name: "Approval",
    type: "event",
  },
];

const Search = () => {
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const [available, setAvailable] = useState(false);
  const [buying, setBuying] = useState(false);

  const { account, library } = useWeb3React();

  const getCryptoHandlesContract = () => {
    const signer = account ? library?.getSigner(account) : library;
    return new ethers.Contract(
      "0x830a520Ca393bF213d4d983A3Ee40254C97ed799",
      abi,
      signer
    );
  };

  const valid = (str) => {
    try {
      ethers.utils.formatBytes32String(str);
      return /^[a-z0-9_]{1,32}$/.test(str);
    } catch {
      return false;
    }
  };

  useEffect(() => {
    let canceled = false;
    const search = async () => {
      if (text) {
        if (valid(text)) {
          setBuying(false);
          setLoading(true);
          const handles = getCryptoHandlesContract();
          const handle = ethers.utils.formatBytes32String(text);
          const available = await handles.isHandleAvailable(handle);
          if (!canceled) {
            setAvailable(available);
            setLoading(false);
          }
        } else {
          setAvailable(false);
        }
      }
    };

    search();

    return () => (canceled = true);
    // eslint-disable-next-line
  }, [text]);

  useEffect(() => {
    if (library) {
      // fetchInfo();
    }
    // eslint-disable-next-line
  }, [account, library]);

  const buy = async () => {
    try {
      if (!account) {
        return;
      }
      setBuying(true);
      const handles = getCryptoHandlesContract();
      const handle = ethers.utils.formatBytes32String(text);

      const tx = await handles.create(handle, {
        value: utils.parseUnits("0.029"),
      });
      await tx.wait();
      setBuying(false);
    } catch (e) {
      setBuying(false);
    }
  };

  const success = text && !loading && available;
  const failure = text && !loading && !available;

  let inputStyle = {
    width: "100%",
    maxWidth: 450,
    borderRadius: 4,
    borderColor: "#dbdbdb",
    boxShadow: "none",
  };
  if (loading) {
    inputStyle = {
      ...inputStyle,
      borderRadius: "4px 4px 0 0",
      borderColor: "#dbdbdb",
      boxShadow: "none",
    };
  }
  if (success && !loading) {
    inputStyle = {
      ...inputStyle,
      borderRadius: "4px 4px 0 0",
      borderColor: "#dbdbdb #dbdbdb #3e8ed0 #dbdbdb",
      boxShadow: "none",
    };
  }

  return (
    <>
      <div className="field" style={{ width: "100%", maxWidth: 450 }}>
        <p
          className={classNames("control has-icons-right", {
            "is-loading": false,
          })}
        >
          <input
            style={inputStyle}
            value={text}
            onChange={(e) => setText(e.target.value)}
            className={classNames("input")}
            type="handle"
            placeholder="Search for handles..."
          />
          {!success && !failure && (
            <span className="icon is-small is-right">
              <i className="fas fa-search" style={{ color: "#DBDBDB" }}></i>
            </span>
          )}
          {success && (
            <span className="icon is-small is-right">
              <i className="fas fa-check has-text-success"></i>
            </span>
          )}
          {failure && (
            <span className="icon is-small is-right">
              <i className="fas fa-times has-text-danger"></i>
            </span>
          )}
          {loading && (
            <progress
              className="progress is-small is-primary"
              max="100"
              style={{ height: 2, borderRadius: 0 }}
            >
              15%
            </progress>
          )}
        </p>
        {success && (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button
              className={classNames("button is-info", {
                "is-loading": buying,
              })}
              style={{
                width: "100%",
                borderTopWidth: 0,
                borderRadius: "0 0 4px 4px",
              }}
              onClick={buy}
            >
              Buy 0.029 ETH
            </button>
          </div>
        )}
      </div>
    </>
  );
};

function App() {
  const { account, activate, deactivate, active } = useWeb3React();

  if (!account && !active) {
    const knownConnector = localStorage.getItem("connector");
    if (knownConnector === "metamask") {
      activate(injected);
    } else {
      activate(network);
    }
  }

  const login = () => {
    localStorage.setItem("connector", "metamask");
    activate(injected);
  };

  const logout = () => {
    localStorage.setItem("connector", "");
    deactivate(injected);
  };

  return (
    <>
      <div className="container">
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 16,
            marginRight: "1.5rem",
          }}
        >
          <div className="buttons">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://discord.gg/gYTFZTJGgA"
              className="button is-info"
            >
              <i className="fab fa-discord"></i>
            </a>
            <a
              className="button is-info"
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/CryptoHandles"
            >
              <i className="fab fa-twitter"></i>
            </a>
            {!account && (
              <button
                className="button"
                onClick={login}
                disabled={!window.ethereum}
              >
                <strong>Connect</strong>
              </button>
            )}
            {account && (
              <button
                className="button"
                onClick={logout}
                disabled={!window.ethereum}
              >
                <strong>Logout</strong>
              </button>
            )}
          </div>
        </div>
      </div>

      <section className="section">
        <div className="container is-max-desktop">
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <Search />
          </div>
        </div>
      </section>
    </>
  );
}

const Wrapper = () => (
  <Web3ReactProvider getLibrary={getLibrary}>
    <App />
  </Web3ReactProvider>
);

export default Wrapper;
